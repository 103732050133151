import { ReactElement } from 'react';

export enum RequirementType {
    Objective = "objective",
    Action = "action",
    Constraint = "constraint",
    Fact = "fact"
}

export interface RequirementItem {
    type: RequirementType;
    title: string;
    description: string;
    icon: ReactElement;
}

export enum NotificationType {
    CompleteSuccess  = "complete-success",
    CompleteFailed   = "complete-failed",
    CompleteOngoing  = "complete-ongoing",
    DecomposeSuccess = "decompose-success",
    DecomposeFailed  = "decompose-failed",
    DecomposeOngoing = "decompose-ongoing",
    EvaluateSuccess  = "evaluate-success",
    EvaluateFailed   = "evaluate-failed",
    EvaluateOngoing  = "evaluate-ongoing",
}

export interface Requirement {
    id: string;
    color: string;
    type: RequirementType;
    requirement: string;
    isSelected: boolean;
    isSubjective: boolean;
}

export interface ResponseRequirement {
    id: string;
    isMet: boolean;
    highlights: string[];
}

export interface Iteration {
    id: string;
    text: string;
    byBot: boolean;
    requirements: Array<Requirement>;
}

export interface Action {
    prompt: string
}
