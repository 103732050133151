import React, { useEffect, useRef, useCallback, useState } from 'react'
import TextCard from '../Iteration/TextCard';
import { addEdge, EdgeTypes, ReactFlow, useEdgesState, useNodesState, useReactFlow, MarkerType, Background, BackgroundVariant } from '@xyflow/react';
import ActionEdge from '../Iteration/ActionEdge';
import { complete } from '../../api'
import PlaceholderNode from '../Iteration/PlaceholderNode';
import FirstNode from '../Iteration/FirstNode/FirstNode';
import '@xyflow/react/dist/base.css';

const nodeTypes = {
  textCard: TextCard,
  placeholderNode: PlaceholderNode,
  firstNode: FirstNode
};

const edgeTypes: EdgeTypes = {
  actionEdge: ActionEdge
};

let id = 0;
const getId = () => `${id++}`;

const Flow = () => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { screenToFlowPosition } = useReactFlow();

  // Use useRef to keep track of the latest state values
  const nodesRef = useRef(nodes);
  const edgesRef = useRef(edges);

  // Sync the refs with state changes
  useEffect(() => {
    nodesRef.current = nodes;
  }, [nodes]);

  useEffect(() => {
    edgesRef.current = edges;
  }, [edges]);

  const callModel = async (nid, prompt, pnid = null) => {
    // Set node to loading state before API call
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nid) {
          return {
            ...node,
            type: 'placeholderNode',
            data: {
              ...node.data,
              loading: true, // Set loading to true
            },
          };
        }
        return node;
      })
    );
    const node = pnid ? nodesRef.current.find((node) => node.id === pnid) : null;
    const response = await complete(prompt, node ? node.data.iteration.text : null);
    return response
  }

  const handleNewPrompt = async (nid: string, prompt: string, pnid: string = null, eid: string = null) => {
    console.log(nid, eid, prompt)
    // call an api to get llm response 
    const response = await callModel(nid, prompt, pnid)
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nid) {
          // it's important that you create a new node object
          // in order to notify react flow about the change
          return {
            ...node,
            type: 'textCard',
            data: {
              ...node.data,
              iteration: {
                requirements: [],
                byBot: true,
                text: response
              }
            },
          };
        }
        return node;
      }),
    );
    if (pnid)
      setEdges((eds) =>
        eds.map((edge) => {
          if (edge.id === eid) {
            console.log({
              ...edge,
              data: {
                ...edge.data,
                prompt: prompt
              },
            })
            return {
              ...edge,
              data: {
                ...edge.data,
                prompt: prompt
              },
            };
          }

          return edge;
        }),
      );
  }

  useEffect(() => {    
    const initialNode = {
      id: getId(),
      type: "firstNode",
      position: { x: window.innerWidth / 2, y: window.innerWidth / 2 },
      data: { onPrompting: handleNewPrompt }
    }
    
    setNodes((nds) => [initialNode]);
  }, [screenToFlowPosition]);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges],
  );
  const onConnectEnd = useCallback(
    (event, connectionState) => {
      // when a connection is dropped on the pane it's not valid
      if (!connectionState.isValid) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = getId();
        const { clientX, clientY } =
          'changedTouches' in event ? event.changedTouches[0] : event;
        const newNode = {
          id,
          position: screenToFlowPosition({
            x: clientX,
            y: clientY,
          }),
          type: 'placeholderNode',
          data: {},
        };
        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({ id, source: connectionState.fromNode.id, target: id, type: 'actionEdge', markerEnd: { type: MarkerType.ArrowClosed }, data: { prompt: "", onPrompting: handleNewPrompt } }),
        );
      }
    },
    [screenToFlowPosition],
  );

  return (
    <div style={{ height: "2000px" }} ref={reactFlowWrapper}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onConnectEnd={onConnectEnd}
        fitView
        fitViewOptions={{ padding: 2 }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
      >
        <Background variant={BackgroundVariant.Lines} />
      </ReactFlow>
    </div>
  );
};

export default Flow;
