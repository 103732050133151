import React from "react"
import Flow from "./components/Flow";
import '@xyflow/react/dist/base.css';
import { ReactFlowProvider } from "@xyflow/react";
import ReqDrawer from "./components/ReqBar";
import { RequirementsProvider } from "./RequirementsProvider";
import { MantineProvider, Affix, Image } from "@mantine/core";
import { Notifications } from '@mantine/notifications';
import vds_logo from './assets/logo/vds.png'

export function App() {
  return (
    <>
      <MantineProvider>
        <Notifications />
        <RequirementsProvider>
          <ReactFlowProvider>
            <Flow />
          </ReactFlowProvider>
          <ReqDrawer />
        </RequirementsProvider>
        <Affix position={{ top: 20, right: 20 }}>
          <Image src={vds_logo} style={{width: 100, height: '100%'}}/>
        </Affix>
      </MantineProvider>
    </>
  );
}
