import chroma from 'chroma-js';

export function getDistinctColors(n) {
  // Generate n distinct colors evenly distributed in HSL space
  const colors = Array.from({ length: n }, (_, i) =>
    chroma.hsl((360 * i) / n, 0.7, 0.8).hex()
  );
  
  return colors;
}
