import React, { useState } from "react"
import { Paper, Popover, Textarea, Text, Stack, Button } from "@mantine/core"
import { EdgeProps, Edge, getBezierPath, BaseEdge, EdgeLabelRenderer} from '@xyflow/react';
import { Action } from "../../../types";

const EnterActionPopOver: React.FC<{value: string, onChange, onSubmit}> = ({value, onChange, onSubmit}) => {
    return (
        <Stack>
        <Textarea
         label="Action"
         description="Enter your prompt here"
         placeholder="e.g. make it more polite" 
         autosize
         resize="horizontal"
         maxRows={5}
         value={value}
         onChange={onChange}
        />
        <Button onClick={onSubmit}>Submit</Button>
        </Stack>
    );
}

const ShowActionPopOver: React.FC<Action> = ({prompt}) => {
    return (
        <Text size="s" c="dimmed">{prompt}</Text>
    );
}

const ActionEdge: React.FC<EdgeProps<Edge<{ prompt: string, onPrompting: (nid: string, pnid: string, eid:string, prompt: string) => void }>>> = (
    {id, source, target, sourceX, sourceY, targetX, targetY,sourcePosition, targetPosition, markerEnd, data}) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition
    });

    const [newPrompt, setNewPrompt] = useState<string>("")

    const handlePromptSubmit = () => {
        console.log(data.onPrompting)
        data.onPrompting(target, newPrompt, source, id);
    }
    
    return (
        <>
        <BaseEdge id={id} path={edgePath} markerEnd={markerEnd}/>
        <EdgeLabelRenderer>
            <div style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                pointerEvents: 'all'
            }}>
            <Popover>
                <Popover.Target>
                    <Paper shadow="sm" style={{maxWidth: '200px', padding:"2px"}}>
                        {(data.prompt === ""? <Text truncate="end" c="#0096FF" size="sm">Add your action</Text> : <Text truncate="end" c="dimmed" size="sm">{data.prompt}</Text>)}
                    </Paper>
                </Popover.Target>
                <Popover.Dropdown>
                    {data.prompt === ""? 
                    <EnterActionPopOver value={newPrompt} onChange={(event) => setNewPrompt(event.currentTarget.value)} onSubmit={handlePromptSubmit}/> : <ShowActionPopOver prompt={data.prompt} />}
                </Popover.Dropdown>
            </Popover>
            </div>
        </EdgeLabelRenderer>
        </>
    );
}

export default ActionEdge;
