import React from "react";
import { Mark, Text } from "@mantine/core"
import { highlighter } from "./highlighter";

const HighlightOpacity = 0.5

const TextWithNewlines = (text: string) => {
    return text.replace(/\n/g, '<br />');
};

export const CustomHighlight = ({ text, highlight }) => {
    const highlightChunks = highlighter(text, highlight);

    const wrapWithMultipleSpans = (chunkText: string, colors: string[], i: number) => {
        return colors.reduceRight<any>((acc, color, idx) => {
            return (
                idx === (colors.length - 1) ?
                    <span key={`${i}-${idx}`} style={{ backgroundColor: color, opacity: HighlightOpacity }}
                        dangerouslySetInnerHTML={{ __html: TextWithNewlines(acc) }} /> :
                    <span key={`${i}-${idx}`} style={{ backgroundColor: color, opacity: HighlightOpacity }}>
                        {acc}
                    </span>
            );
        }, chunkText);
    };

    return (
        <Text>
            {highlightChunks.map(({ chunk, highlighted, colors }, i) =>
                highlighted ? (
                    wrapWithMultipleSpans(chunk, colors, i)
                ) : (
                    <span key={i} dangerouslySetInnerHTML={{ __html: TextWithNewlines(chunk) }} />
                )
            )}
        </Text>
    );
};

export default CustomHighlight;
