interface Highlighting {
    colors: string[];
    terms: string[];
};

interface Chunk {
    chunk: string;
    highlighted: boolean;
    colors?: string[];
};

export function highlighter(value: string, highlights: Highlighting[]): Chunk[] {
    // Initialize an array to store the chunks
    let result: Chunk[] = [];

    // Array to keep track of character-based highlights
    let highlightArray: { highlighted: boolean, colors: Set<string> }[] = Array(value.length).fill(null).map(() => ({
        highlighted: false,
        colors: new Set<string>()
    }));

    // Iterate over each highlight group and mark the relevant parts of the string
    highlights.forEach(highlight => {
        highlight.terms.forEach(term => {
            let startIndex = value.toLowerCase().indexOf(term.toLowerCase());
            while (startIndex !== -1) {
                const endIndex = startIndex + term.length;
                // Mark each character in the term as highlighted
                for (let i = startIndex; i < endIndex; i++) {
                    highlightArray[i].highlighted = true;
                    highlightArray[i].colors = new Set([...highlightArray[i].colors, ...highlight.colors]);
                }
                // Continue searching for the term in the string
                startIndex = value.toLowerCase().indexOf(term.toLowerCase(), startIndex + 1);
            }
        });
    });

    // Now, we need to create chunks by looking at the highlightArray
    let currentChunk = '';
    let currentHighlightStatus = highlightArray[0]?.highlighted;
    let currentColors = highlightArray[0]?.colors || new Set<string>();

    for (let i = 0; i < value.length; i++) {
        const char = value[i];
        const highlightStatus = highlightArray[i]?.highlighted;
        const colors = highlightArray[i]?.colors || new Set<string>();

        if (highlightStatus !== currentHighlightStatus || !setEqual(colors, currentColors)) {
            // Push the current chunk to the result
            result.push({
                chunk: currentChunk,
                highlighted: currentHighlightStatus,
                colors: currentHighlightStatus ? Array.from(currentColors) : undefined
            });

            // Start a new chunk
            currentChunk = char;
            currentHighlightStatus = highlightStatus;
            currentColors = colors;
        } else {
            currentChunk += char;
        }
    }

    // Push the final chunk
    if (currentChunk) {
        result.push({
            chunk: currentChunk,
            highlighted: currentHighlightStatus,
            colors: currentHighlightStatus ? Array.from(currentColors) : undefined
        });
    }

    return result;
}

// Helper function to compare two sets for equality
function setEqual(setA: Set<any>, setB: Set<any>): boolean {
    if (setA.size !== setB.size) return false;
    for (let item of setA) if (!setB.has(item)) return false;
    return true;
}