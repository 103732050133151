import React from "react";
import { NotificationType } from "./types";
import { IconX, IconCheck } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { rem } from "@mantine/styles";

const sutoCloseTime = 5000

export const NotificationsManager = (notification_type: NotificationType, prev_id: string | null = null) => {
    switch (notification_type) {
        case NotificationType.CompleteOngoing:
            return notifications.show({
                loading: true,
                color: 'blue',
                title: "Ongoing Generation.",
                message: "We're generating your response.",
                autoClose: false,
                withCloseButton: false,
            });
        case NotificationType.CompleteSuccess:
            return notifications.update({
                id: prev_id,
                color: 'teal',
                title: 'Successful Generation',
                message: 'Your response has been succussfully generated.',
                icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: sutoCloseTime,
                withCloseButton: true
            })
        case NotificationType.CompleteFailed:
            return notifications.update({
                id: prev_id,
                color: 'red',
                title: 'Failed Generation',
                message: 'We encountered an error while generating your response.',
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: false,
                withCloseButton: true
            })
        case NotificationType.DecomposeOngoing:
            return notifications.show({
                loading: true,
                color: 'blue',
                title: "Ongoing Extraction.",
                message: "We're extracting your requiremnts.",
                autoClose: false,
                withCloseButton: false,
            });
        case NotificationType.DecomposeSuccess:
            return notifications.update({
                id: prev_id,
                color: 'teal',
                title: 'Successful Extraction',
                message: 'Your requirements have been succussfully extracted.',
                icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: sutoCloseTime,
                withCloseButton: true
            })
        case NotificationType.DecomposeFailed:
            return notifications.update({
                id: prev_id,
                color: 'red',
                title: 'Failed Extraction',
                message: 'We encountered an error while extracting your requirements.',
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: false,
                withCloseButton: true
            })
        case NotificationType.EvaluateOngoing:
            return notifications.show({
                loading: true,
                color: 'blue',
                title: "Ongoing Evaluation.",
                message: "We're evaluating the new response against your requiremnts.",
                autoClose: false,
                withCloseButton: false,
            });
        case NotificationType.EvaluateSuccess:
            return notifications.update({
                id: prev_id,
                color: 'teal',
                title: 'Successful Evaluation',
                message: 'The new response has been succussfully evaluated against your requirements.',
                icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: sutoCloseTime,
                withCloseButton: true
            })
        case NotificationType.EvaluateFailed:
            return notifications.update({
                id: prev_id,
                color: 'red',
                title: 'Failed Evaluation',
                message: 'We encountered an error while evaluating the response.',
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: false,
                withCloseButton: true
            })
    }
}