import React, { useContext, ReactElement } from "react"
import { Switch, Accordion, Group, Text, AppShell, Title } from '@mantine/core';
import RequirementsContext from "../../RequirementsProvider";
import { requirementsItems } from "../../constants";

interface AccordionCoverProps {
    title: string;
    icon: ReactElement;
    description: string;
}

function AccordionCover({ title, icon, description }: AccordionCoverProps) {
    return (
        <Group wrap="nowrap">
            {icon}
            <div>
                <Text>{title}</Text>
                <Text size="sm" c="dimmed" fw={400}>
                    {description}
                </Text>
            </div>
        </Group>
    );
}

const RequirementSwitch = ({ r }) => {
    const { requirements, editRequirement } = useContext(RequirementsContext);

    const handleSwitchChange = (event) => {
        editRequirement(r.id);
    };

    return (
        <Switch
            label={r.requirement}
            color={r.color}
            disabled={r.isSubjective === true}
            onChange={handleSwitchChange}
            checked={r.isSelected}
            style={{ padding: "2%" }}
        />
    );
};

const ReqBar = () => {
    const { requirements } = useContext(RequirementsContext)

    return (
        <>
            <AppShell>
                <AppShell.Navbar style={{ padding: '1%', overflowY: 'auto', width: "20%", maxWidth: '500px', minWidth: '300px' }} >
                    <Title order={4}>Requirements</Title>
                    <Accordion multiple>
                        {requirementsItems.map((item) => (
                            <Accordion.Item key={item.type} value={item.title}>
                                <Accordion.Control>
                                    <AccordionCover {...item} />
                                </Accordion.Control>
                                <Accordion.Panel>
                                    {requirements
                                        .filter((r) => r.type === item.type)
                                        .map((r) => (
                                            <RequirementSwitch key={r.id} r={r} />
                                        ))}
                                </Accordion.Panel>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </AppShell.Navbar>
            </AppShell>
        </>
    );
}

export default ReqBar;
