import React from "react"
import { Card, Text, Skeleton, Stack } from "@mantine/core"
import { SiProbot } from "react-icons/si";
import { Handle, Position } from '@xyflow/react';

interface PlaceholdernodeProps {
    data: { loading: boolean }
}


const skeletonLinesWidths = Array.from({ length: 25 }).map(() => (
    `${Math.random() * (100 - 50) + 50}%`
))


const PlaceholderNode: React.FC<PlaceholdernodeProps> = ({ data }) => {
    const { loading = false } = data

    return (
        <>
            <Card withBorder shadow="none" radius="md" style={{ width: (loading ? '200px' : '100px'), border: '2px dashed #0096FF', }}>
                {!loading ? (
                    <Card.Section withBorder>
                        <Text ta="center" p="0.2rem" c="dimmed" size="lg"><SiProbot /></Text>
                    </Card.Section>
                ) : (
                    <Card.Section withBorder style={{ padding: '7%', height: '300px' }}>
                        <Stack gap={5}>
                            {skeletonLinesWidths.map((w, index) => (
                                <Skeleton key={index} height={8} width={w} />
                            ))}
                        </Stack>
                    </Card.Section>)}
            </Card>
            <Handle
                type="target"
                position={Position.Left}
            />
        </>
    );
}

export default PlaceholderNode;
