import React, { useState, useContext, useEffect } from "react"
import { Card, Text } from "@mantine/core"
import { Iteration, ResponseRequirement } from "../../../types"
import { SiProbot } from "react-icons/si";
import { Handle, Position } from '@xyflow/react';
import RequirementsContext from "../../../RequirementsProvider";
import { evaluate } from "../../../api";
import CustomHighlight from "../../CustomHighlight";

interface TextCardProps {
    data: { iteration: Iteration, loading: boolean, maxLines: number }
}

const TextCard: React.FC<TextCardProps> = ({ data }) => {
    const { iteration } = data
    const { requirements, mainPrompt } = useContext(RequirementsContext)
    const [responseRequirements, setResponseRequirements] = useState<ResponseRequirement[]>([])

    useEffect(() => {
        const callEvaluate = async () => {
            const response = await evaluate(mainPrompt, iteration.text, requirements.filter(r => r.isSubjective !== true))
            setResponseRequirements(response)
        }
        callEvaluate()
    }, [])

    let highlights = []
    responseRequirements.forEach(rr => {
        requirements.forEach(r => {
            if (rr.id === r.id && r.isSelected) {
                highlights.push({
                    terms: rr.highlights,
                    colors: [r.color]
                })
            }
        })
    })

    return (
        <>
            <Card withBorder shadow="sm" radius="md" style={{ maxWidth: "300px" }}>
                <CustomHighlight text={iteration.text} highlight={highlights} />
                <Card.Section withBorder>
                    <Text ta="center" p="0.2rem" c="dimmed" size="lg"><SiProbot /></Text>
                </Card.Section>
            </Card>
            <Handle
                type="source"
                position={Position.Right}
                onConnect={(params) => console.log('handle onConnect', params)}
            />
            <Handle
                type="target"
                position={Position.Left}
                onConnect={(params) => console.log('handle onConnect', params)}
            />
        </>
    );
}

export default TextCard;
