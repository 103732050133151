import React, { useState, useContext } from "react"
import { Card, Stack, Textarea, Button, Text, Mark, Highlight } from "@mantine/core"
import { FaArrowRight } from "react-icons/fa";
import RequirementsContext from "../../../RequirementsProvider";

const FirstNode = ({ id, data }) => {
    const [newPrompt, setNewPrompt] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const { getRequirements } = useContext(RequirementsContext)

    const handlePromptSubmit = async () => {
        setLoading(true)
        await getRequirements(newPrompt)
        setLoading(false)
        data.onPrompting(id, newPrompt);
    }

    return (
        <>
            <Card withBorder shadow="sm" radius="md" style={{ maxWidth: "300px" }}>
                <Stack>
                    <Textarea
                        className="nodrag"
                        label="Initial Prompt"
                        description="Enter your initial prompt here, make sure to include you requirements in detail."
                        placeholder="e.g. write an email to my boss to request a sick leave from [date] to [date]. Use a formal and polite tone and keep it concise."
                        autosize
                        resize="vertical"
                        maxRows={10}
                        value={newPrompt}
                        onChange={(event) => setNewPrompt(event.currentTarget.value)}
                    />
                    <Button loading={loading} onClick={handlePromptSubmit} rightSection={<FaArrowRight />}>Submit</Button>
                </Stack>
            </Card>
        </>
    );
}

export default FirstNode;
