import React, { createContext, useState } from 'react';
import { Requirement } from './types';
import { decompose } from './api'
import { v4 as uuidv4 } from 'uuid';
import { getDistinctColors } from './utils';

interface RequirementsContextType {
    mainPrompt: string;
    requirements: Requirement[];
    getRequirements: (prompt: string) => Promise<void>;
    addRequirement: () => void;
    removeRequirement: () => void;
    editRequirement: (id: string) => void;
}

const RequirementsContext = createContext<RequirementsContextType | undefined>(undefined);

export const RequirementsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [mainPrompt, setMainPrompt] = useState<string>("");
    const [requirements, setRequirements] = useState<Requirement[]>([]);

    const getRequirements = async (prompt) => {
        setMainPrompt(prompt)

        const response = await decompose(prompt)

        const colors = getDistinctColors(response.length)
        setRequirements(response.map((req, index) => { return { ...req, id: uuidv4(), color: colors[index], isSelected: false } }))
    };

    const addRequirement = () => {
        // manually by user
    };

    const removeRequirement = () => {
        // manually by user
    }

    const editRequirement = (id: string) => {
        setRequirements((prevRequirements) =>
            prevRequirements.map((r) =>
                r.id === id ? { ...r, isSelected: !r.isSelected } : r
            )
        );
        console.log(id)
    }

    return (
        <RequirementsContext.Provider value={{ mainPrompt, requirements, getRequirements, addRequirement, removeRequirement, editRequirement }}>
            {children}
        </RequirementsContext.Provider>
    );
};

export default RequirementsContext;