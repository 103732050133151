import axios from 'axios';
import { config } from './config'
import { NotificationType, Requirement } from './types';
import { NotificationsManager } from './NotificationsManager';

const api = axios.create({
  baseURL: config.baseURL
});

export const complete = async (prompt: string, reply: string = null) => {
  const payload = { prompt: prompt }
  reply != null && (payload["prev_reply"] = reply)
  let ntfId = null
  try {
    ntfId = NotificationsManager(NotificationType.CompleteOngoing)
    const response = await api.post(`/complete`, payload);
    NotificationsManager(NotificationType.CompleteSuccess, ntfId)
    return response.data.response
  } catch (error) {
    NotificationsManager(NotificationType.CompleteFailed, ntfId)
    console.error('Error generating a response:', error);
  }
};

export const decompose = async (prompt: string) => {
  const payload = { prompt: prompt }
  let ntfId = null
  try {
    ntfId = NotificationsManager(NotificationType.DecomposeOngoing)
    const response = await api.post(`/prompt/decompose`, payload);
    NotificationsManager(NotificationType.DecomposeSuccess, ntfId)
    return response.data.requirements
  } catch (error) {
    NotificationsManager(NotificationType.DecomposeFailed, ntfId)
    console.error('Error decomposing the requirements:', error);
  }
};

export const evaluate = async (prompt: string, response: string, requirements: Requirement[]) => {
  const payload = { prompt: prompt, response: response, requirements: requirements }
  let ntfId = null
  try {
    ntfId = NotificationsManager(NotificationType.EvaluateOngoing)
    const response = await api.post(`/response/evaluate`, payload);
    NotificationsManager(NotificationType.EvaluateSuccess, ntfId)
    return response.data.evaluation
  } catch (error) {
    NotificationsManager(NotificationType.EvaluateFailed, ntfId)
    console.error('Error evaluating the requirements:', error);
  }
};
