import { FcCollect, FcDisclaimer, FcFlashOn, FcInfo } from "react-icons/fc";
import { RequirementItem, RequirementType } from "./types";
import React from "react";

export const requirementsItems: RequirementItem[] = [
    {
        type: RequirementType.Action,
        title: "Actions",
        description: "The core operations that the prompt is asking for.",
        icon: <FcFlashOn size={70} />

    },
    {
        type: RequirementType.Objective,
        title: "Objectives",
        description: "The intended outcomes that the action is meant to achieve. It reflects what the user ultimately wants to accomplish.",
        icon: <FcCollect size={100} />

    },
    {
        type: RequirementType.Constraint,
        title: "Constraints",
        description: "Specific conditions or limitations that must be adhered to while performing the action.",
        icon: <FcDisclaimer size={100} />

    },
    {
        type: RequirementType.Fact,
        title: "Facts",
        description: "Pieces of information that surrounds the action with broader background. It needs to be acknowledged or reflected in the output but do not directly constrain the form of the action.",
        icon: <FcInfo size={130} />

    }
]
